/**
 * Header showing
 */
const header = () => {
  const headerShow = document.querySelector('.header');
  const menu = document.querySelector('.js-menu-burger');
  const menuItem = document.querySelectorAll('.header__list-link');

  if (headerShow && menu) {
    menu.addEventListener('click', () => {
      menu.classList.toggle('active');
      headerShow.classList.toggle('active');
      document.body.classList.toggle('overflow');
    });

    window.addEventListener('scroll', () => {
      if (window.scrollY >= (window.innerHeight - 100)) {
        headerShow?.classList.add('fixed');
      } else {
        headerShow?.classList.remove('fixed');
      }
    });

    menuItem.forEach(item => {
      item.addEventListener('click', () => {
        document.body.classList.remove('overflow');
        menu.classList.remove('active');
        headerShow.classList.remove('active');
      });
    });
  }
};

export default header;
