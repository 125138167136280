import header from './components/header';
import splideMv from './components/splide';
import tableModal from './components/tableModa';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    header();
    splideMv();
    tableModal();
  },
  false
);
