/**
 * Splide MV
 */

declare var Splide : any;

const splideMv = () => {
  const splideMain = document.querySelector('.js-main-splide');
  const splideThumb = document.querySelector('.js-thumb-splide');
  // let splideMainSlider: any;

  var splideMainSlider = new Splide(splideMain, {
    type: 'loop',
    perPage: 1,
    rewind: true,
    gap: '5%',
    focus: 'center',
    arrows: false,
    pagination: false,
    mediaQuery: 'min',
    breakpoints: {
      768: {
        arrows: false,
        pagination: false,
        destroy: true,
      },
    },
  });

  var splideThumbSlider = new Splide(splideThumb, {
    fixedWidth : 48,
    fixedHeight: 48,
    gap         : 9,
    rewind      : true,
    pagination  : false,
    isNavigation: true,
    arrows: false,
    mediaQuery: 'min',
    breakpoints: {
      768: {
        arrows: false,
        pagination: false,
        destroy: true,
      },
    },
  });

  splideMainSlider.sync( splideThumbSlider );
  splideMainSlider.mount();
  splideThumbSlider.mount();
};

export default splideMv;
