/**
 * Toggle table at SP View
 */

const tableModal = () => {
  const modalTrigger = document.querySelector('.js-modal');

  if (modalTrigger) {
    const modalBtn = document.querySelector('.js-modal-btn');
    const modalCont = document.querySelector('.js-modal-cont');
    const modalClose = document.querySelector('.js-modal-close');

    modalBtn?.addEventListener('click', () => {
      modalTrigger?.classList.add('active');
      modalBtn?.classList.add('active');
      modalCont?.classList.add('active');
    });

    modalClose?.addEventListener('click', () => {
      modalTrigger?.classList.remove('active');
      modalBtn?.classList.remove('active');
      modalCont?.classList.remove('active');
    });
  }
};

export default tableModal;